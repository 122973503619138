import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import BannerHeader from "./BannerHeader";

export default function Header() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isParceriasDropdownOpen, setIsParceriasDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Alterna o dropdown de "Institucional"
  const handleInstitucionalToggle = (e) => {
    e.preventDefault();
    setIsDropdownOpen((prev) => !prev);
  };

  // Alterna o dropdown de "Parcerias"
  const handleParceriasToggle = (e) => {
    e.preventDefault();
    setIsParceriasDropdownOpen((prev) => !prev);
  };

  // Fecha os dropdowns ao clicar em um item
  const closeDropdown = () => {
    setIsDropdownOpen(false);
    setIsParceriasDropdownOpen(false);
  };

  // Alterna o menu móvel
  const handleMenuToggle = () => {
    setIsMenuOpen((prev) => !prev);
    const navList = document.querySelector(".cs-nav_list");
    if (navList) {
      navList.style.display = isMenuOpen ? "none" : "block";
    }
  };

  // Trata cliques nos itens do menu
  const handleMenuItemClick = (e) => {
    const target = e.currentTarget.getAttribute("href");
    const isInternalLink = target.startsWith("#"); // Verifica se o link é interno

    if (isInternalLink) {
      // Navega suavemente até a seção correspondente
      e.preventDefault();
      const targetElement = document.querySelector(target);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }

    // Sempre fecha o menu e volta para o ícone hambúrguer
    setIsMenuOpen(false);
    const navList = document.querySelector(".cs-nav_list");
    if (navList) {
      navList.style.display = "none";
    }
  };

  return (
    <>
      <header className="cs-site_header cs-style1 cs-sticky-header cs-primary_color cs-white_bg">
        <BannerHeader />
        <div className="cs-main_header">
          <div className="container">
            <div className="cs-main_header_in">
              <div className="cs-main_header_left">
                <a className="cs-site_branding cs-accent_color" href="/">
                  <img
                    src="../../../img/cashlink/logo_cashlink.png"
                    alt="Logo"
                  />
                </a>
              </div>
              <div className="cs-main_header_center">
                <div className="cs-nav">
                  <ul className="cs-nav_list">
                    <li>
                      <a
                        href="/#maquininhas"
                        className="cs-smoth_scroll"
                        onClick={handleMenuItemClick}
                      >
                        Maquininhas
                      </a>
                    </li>
                    <li>
                      <a
                        href="/planos"
                        className="cs-smoth_scroll"
                        onClick={handleMenuItemClick}
                      >
                        Planos e Taxas
                      </a>
                    </li>
                    {/* Dropdown Parcerias */}
                    <li
                      className={`dropdown ${
                        isParceriasDropdownOpen ? "open" : ""
                      }`}
                    >
                      <button
                        className="dropdown-toggle"
                        onClick={handleParceriasToggle}
                        aria-expanded={isParceriasDropdownOpen}
                      >
                        Parcerias
                      </button>
                      <ul
                        className={`dropdown-menu ${
                          isParceriasDropdownOpen ? "show" : ""
                        }`}
                      >
                        <li>
                          <a
                            href="https://wa.me/message/ZGCPJHTCM75TJ1"
                            className="cs-smoth_scroll"
                            onClick={(e) => {
                              handleMenuItemClick(e);
                              closeDropdown();
                            }}
                          >
                            Seja um Link
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://afiliados.cashlinkpay.com.br"
                            className="cs-smoth_scroll"
                            onClick={(e) => {
                              handleMenuItemClick(e);
                              closeDropdown();
                            }}
                          >
                            Afiliados
                          </a>
                        </li>
                        <li>
                          <a
                            href="/beneficios"
                            className="cs-smoth_scroll"
                            onClick={(e) => {
                              handleMenuItemClick(e);
                              closeDropdown();
                            }}
                          >
                            Benefícios Parceiros
                          </a>
                        </li>
                      </ul>
                    </li>
                    {/* Dropdown Institucional */}
                    <li
                      className={`dropdown ${isDropdownOpen ? "open" : ""}`}
                    >
                      <button
                        className="dropdown-toggle"
                        onClick={handleInstitucionalToggle}
                        aria-expanded={isDropdownOpen}
                      >
                        Institucional
                      </button>
                      <ul
                        className={`dropdown-menu ${
                          isDropdownOpen ? "show" : ""
                        }`}
                      >
                        <li>
                          <a
                            href="/sobre"
                            className="cs-smoth_scroll"
                            onClick={(e) => {
                              handleMenuItemClick(e);
                              closeDropdown();
                            }}
                          >
                            Sobre Nós
                          </a>
                        </li>
                        <li>
                          <a
                            href="/#news"
                            className="cs-smoth_scroll"
                            onClick={(e) => {
                              handleMenuItemClick(e);
                              closeDropdown();
                            }}
                          >
                            Blog
                          </a>
                        </li>
                        <li>
                          <a
                            href="/simulador"

                            className="cs-smoth_scroll"
                            onClick={(e) => {
                              handleMenuItemClick(e);
                              closeDropdown();
                            }}
                          >
                            Simulador
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://conta.cashlinkpay.com.br/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Central de Ajuda
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://conta.cashlinkpay.com.br/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Portal CashLink
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a
                        href="https://wa.me/message/TECZ3QFXWQH5H1"
                        className="cs-smoth_scroll"
                        onClick={handleMenuItemClick}
                        target="__blank"
                      >
                        Ajuda
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="cs-main_header_right">
                <div className="cs-toolbox">
                  <a
                    className="cs-link cs-modal_btn"
                    href="https://conta.cashlinkpay.com.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Login</strong>
                  </a>
                  <button 
                    className="cs-btn cs-color1 cs-modal_btn btn-pecaja" 
                    onClick={() => window.location.href = 'https://wa.me/message/PADBZSIWRBIJE1'}
                  >
                    Peça já!
                  </button>
                </div>
              </div>
              {/* Botão do Menu */}
              <button
                className="cs-munu_toggle"
                onClick={handleMenuToggle}
                aria-label="Toggle navigation"
              >
                <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
