import React from "react";
import img1 from "../../../assets/img/cashlink/03.png";
import img2 from "../../../assets/img/cashlink/04.png";
import img3 from "../../../assets/img/cashlink/05.png";
import img4 from "../../../assets/img/cashlink/11.png";

const SobreNos = () => {
  return (
    <section className="sobre-nos cs-gray_bg">
      <div className="container">
        {/* Título */}
        <div className="secao-titulo cs-seciton_heading cs-style1 text-center">
          <h2 className="cs-section_title cs-primary_color">Sobre nós</h2>
          <p className="cs-section_subtitle cs-accent_color">
          
          </p>
          <p className="descricao-principal">
            A <strong>CashLink</strong> nasceu para modernizar os meios de
            pagamento no Brasil. Criada em <strong>novembro de 2024</strong>, já
            revolucionamos o mercado com as menores taxas e máquinas de cartão
            de última geração.
          </p>
        </div>

        {/* Imagens principais */}
        <div className="sobre-nos-imagens">
          <img src={img1} alt="Máquina CashLink 1" />
          <img src={img2} alt="Máquina CashLink 2" />
          <img src={img3} alt="Pagamento CashLink" />
        </div>

        {/* Destaques */}
        <div className="sobre-nos-destaques">
          <div className="texto-bloco">
            <p>
              Nossa missão é facilitar o dia a dia dos negócios,
              proporcionando segurança, agilidade e confiança em cada
              transação. Atuamos em empresas de todos os portes, oferecendo
              soluções transparente e acessíveis.
            </p>
            <p>
              Projeções indicam que a CashLink movimentará mais de{" "}
              <strong>2 bilhões de reais</strong> em transações até 2025.
            </p>
          </div>
          <div className="imagem-bloco">
            <img src={img4} alt="Máquina em uso" />
          </div>
        </div>

        {/* Texto final */}
        <div className="sobre-nos-final text-center">
          <p>
            Comprometida com inovação e excelência, a{" "}
            <strong>CashLink</strong> se torna a escolha ideal para transformar
            o seu negócio.
          </p>
        </div>
      </div>
    </section>
  );
};

export default SobreNos;
