import { createBrowserRouter } from "react-router-dom";
import Home from "../Pages/Home";
import Planos from "../Pages/Planos";
import Sobre from "../Pages/Sobre";
import Beneficios from "../Pages/Beneficios";
import Simulador from "../Pages/Simulador";
import Layout from "../component/Layout/Layout";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/planos",
        element: <Planos />,
      },
      {
        path: "/sobre",
        element: <Sobre />,
      },
      {
        path: "/beneficios", 
        element: <Beneficios />, 
      },
      {
        path: "/simulador",
        element: <Simulador />,
      },
    ],
  },
]);
