import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const plans = [
  {
    title: "Ultra Link",
    description: "Independentemente das vendas, taxa ultra baixa",
    debitRate: "1,20%",
    creditRate: "3,27%",
    credit12xRate: "10,87%",
    credit18xRate: "16,19%",
    pixRate: "0,60%",
    extraDescription: 
      "Ideal para estabelecimentos com alta demanda em vendas à vista, sem abrir mão da flexibilidade nas vendas parceladas."
  },
  {
    title: "Master Link",
    description: "Pra quem vende muito parcelado e com Máquinas mais baratas",
    debitRate: "1,29%",
    creditRate: "3,52%",
    credit12xRate: "11,87%",
    credit18xRate: "16,20%",
    pixRate: "0,65%",
    extraDescription:
      "Ideal para estabelecimentos com alta demanda em vendas parceladas, sem abrir mão das vendas à vista.",
  },
  {
    title: "Super Link",
    description: "Pra quem vende muito à vista e com Máquinas mais baratas.",
    debitRate: "1,25%",
    creditRate: "3,36%",
    credit12xRate: "15,54%",
    credit18xRate: "19,45%",
    pixRate: "0,65%",
    extraDescription:
      "Ideal para estabelecimentos com alta demanda em vendas à vista, sem abrir mão das vendas parceladas.",
  },
  {
    title: "Flex Link",
    description: "Para quem tem Faturamento acima de R$ 50.000,00 reais.",
    debitRate: "Personalizada",
    creditRate: "Personalizada",
    credit12xRate: "Personalizada",
    credit18xRate: "Personalizada",
    pixRate: "Personalizada",
    extraDescription:
      "Consulte as condições exclusivas para sua empresa e aproveite benefícios personalizados com nossos parceiros para impulsionar o crescimento do seu negócio.",
  },
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

export default function PlansCarousel() {
  return (
    <section className="cs-bg py-16">
      <div className="container" >
        
        {/* Título centralizado */}
        <br></br>
        <h3 className="cs-section_title text-center mb-8 font-bold text-xl">
          Conheça os Planos CashLink
        </h3>
        <br></br >
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={false}
          keyBoardControl={true}
          containerclassName="carousel-container"
          itemclassName="carousel-item-padding-4px" // Classe adicionada para espaçamento
        >
          {plans.map((plan, index) => (
            <div
              key={index}
              className="plan-card relative bg-white p-4 rounded-lg shadow-md flex flex-col items-center text-center"
            >
              {/* Conteúdo centralizado */}
              <div className="flex flex-col items-center gap-2">
                <h5 className="font-heading text-lg font-bold">{plan.title}</h5>
                <p className="plan-description text-sm text-gray-600">
                  {plan.description}
                </p>
                <div className="grid grid-cols-3 gap-4 text-center mt-4 rates">
                  <div>
                    <b className="text-sm">DÉBITO</b>
                    <p>{plan.debitRate}</p>
                  </div>
                  <div>
                    <b className="text-sm">CRÉDITO</b>
                    <p>{plan.creditRate}</p>
                  </div>
                  <div>
                    <b className="text-sm">CRÉDITO 12X</b>
                    <p>{plan.credit12xRate}</p>
                  </div>
                  <div>
                    <b className="text-sm">CRÉDITO 18X</b>
                    <p>{plan.credit18xRate}</p>
                  </div>
                  <div>
                    <b className="text-sm">PIX</b>
                    <p>{plan.pixRate}</p>
                  </div>
                </div>
                <a href="/planos" className="cs-btn mt-4">
                  <span>Saiba Mais</span>
                </a>
                {/* Margem divisória */}
                <div className="w-full h-px bg-gray-300 my-4"></div>
                {/* Descrição adicional */}
                <p className="text-sm text-gray-600">{plan.extraDescription}</p>
              </div>
            </div>
          ))}
        </Carousel>
        <div className="cs-height_60 cs-height_lg_60"></div>
      </div>
    </section>
  );
}
