import React from "react";
import Preloader from "../../component/Preloader/Preloader";
import Beneficios from "./Beneficios/Beneficios"

export default function Home() {
  return (
    <div>
      <Preloader></Preloader>
      <div className="cs-height_60 cs-height_lg_60" ></div>
      <div className="cs-height_60 cs-height_lg_60" ></div>
      <div className="cs-height_60 cs-height_lg_60" ></div>
      <h3 className="cs-section_title text-center mb-8 font-bold text-xl">
        Benefícios
      </h3>
      <Beneficios/>
      <div className="cs-height_75 cs-height_lg_70"></div>
    </div>
  );
}
