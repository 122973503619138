import React from 'react';

const Widget = () => {
  return (
    <div id="widget-container" style={widgetStyles.container}>
      <a
        href="https://wa.me/message/TECZ3QFXWQH5H1"
        target="_blank"
        rel="noopener noreferrer"
        style={widgetStyles.button}
        className="whatsapp-button"
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="WhatsApp"
          style={widgetStyles.icon}
        />
      </a>
      <style>
        {`
          /* Animação de borda piscando */
          .whatsapp-button {
            animation: pulse-border 1.5s infinite;
          }

          @keyframes pulse-border {
            0% {
              box-shadow: 0 0 5px 2px rgba(74, 115, 244, 0.8); /* Cor inicial */
            }
            50% {
              box-shadow: 0 0 20px 10px rgba(74, 115, 244, 0.6); /* Cor no pico */
            }
            100% {
              box-shadow: 0 0 5px 2px rgba(74, 115, 244, 0.8); /* Cor final */
            }
          }
        `}
      </style>
    </div>
  );
};

// Estilos inline para o widget
const widgetStyles = {
  container: {
    position: 'fixed',
    bottom: '100px',
    right: '20px',
    zIndex: 1000,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#25D366',
    color: '#fff',
    textDecoration: 'none',
    padding: '10px', // Background menor
    borderRadius: '50%',
    fontWeight: 'bold',
    width: '65px', // Tamanho do botão reduzido
    height: '65px',
    transition: 'all 0.3s ease-in-out',
    overflow: 'hidden',
  },
  icon: {
    width: '50px', // Ícone maior que o background
    height: '50px',
    position: 'absolute', // Posiciona o ícone no centro
  },
};

export default Widget;
