import React from "react";

export default function Hero() {
  return (
    <div id="home">
      <div className="cs-height_80 cs-height_lg_80"></div>
      <section
        className="cs-hero cs-style1 cs-bg"
        data-src="../../../img/hero_bg.svg"
      >
        <div className="container">
          <div className="cs-hero_img">
            <div
              className="cs-hero_img_bg cs-bg"
              data-src="../../../img/cashlink/"
            ></div>
            <img
              src="../../../img/cashlink/01.png"
              alt="POS CashLink"
              className="wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
            />
          </div>
          <div className="cs-hero_text">
            <div className="cs-title-header">
              <strong>FAÇA PARTE DA REVOLUÇÃO!</strong>
            </div>
            <h1 className="cs-hero_title">
              <br></br>A melhor <br></br>maquininha <br></br>na palma <br></br>da sua mão!
            </h1>
            <div className="cs-hero_subtitle">
              Temos a maquininha perfeita para você aumentar seus lucros!<br></br>
              Venda com múltiplas bandeiras, parcele em até 18x e receba o valor<br></br>
              das suas vendas todo dia útil 🔥
            </div>
            <a href="https://wa.me/message/6PK5UN4GOLPXH1" className="cs-btn" target="__blank">
              <span>PEÇA MÁQUININHA COM DESCONTO!</span>
            </a>
          </div>
          <div className="cs-hero_shapes">
            <div className="cs-shape cs-shape_position1">
              <img src="../../../img/shape/shape_1.svg" alt="CashLink Pagamentos" />
            </div>
            <div className="cs-shape cs-shape_position2">
              <img src="../../../img/shape/shape_2.svg" alt="CashLink Pagamentos" />
            </div>
            <div className="cs-shape cs-shape_position3">
              <img src="../../../img/shape/shape_3.svg" alt="CashLink Pagamentos" />
            </div>
            <div className="cs-shape cs-shape_position4">
              <img src="../../../img/shape/icone_logo-02.png" alt="CashLink Pagamentos" />
            </div>
            <div className="cs-shape cs-shape_position5">
              <img src="../../../img/shape/icone_logo-03.png" alt="CashLink Pagamentos" />
            </div>
            <div className="cs-shape cs-shape_position6">
              <img src="../../../img/shape/icone_logo-01.png" alt="CashLink Pagamentos" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
