import React from "react";

export default function Beneficios() {
  return (
    <div className="cs-beneficios_page cs-gray_bg cs-padding">
      <div className="container">
        <div className="cs-seciton_heading cs-style1 text-center">
          <div className="cs-height_40"></div>
          <h2 className="cs-section_title">
            Benefícios Exclusivos para Clientes
          </h2>
          <div className="cs-height_40"></div>
          <h6 className="cs-section_subtitle">
            <div className="cs-height_75 cs-height_lg_70"></div>
            Clientes das maquininhas da <strong>CashLink</strong> aproveitam até{" "}
            <span className="cs-accent_color">70% de desconto</span> nos planos
            de assinatura da <strong>Player4 Studios</strong>!
          </h6>
        </div>

        <div className="cs-blog_details">
          <div className="cs-height_75 cs-height_lg_70"></div>

          <ul className="cs-list cs-style1">
            <li>
              <strong ><a
                href="https://zaplinkshop.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                ZapLink Shop: 
              </a></strong> Crie sua loja virtual no WhatsApp e
              impulsione suas vendas com integração de pagamentos e ferramentas
              exclusivas.{" "}
              <a
                href="https://zaplinkshop.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visite o site
              </a>
              .
            </li>
            <li>
              <strong ><a
                  href="https://zaplinkcrm.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ZapLink CRM: 
                </a></strong> Um CRM completo para gerenciar o
              relacionamento com clientes, focado em otimizar a comunicação via
              WhatsApp e melhorar o atendimento.{" "}
              <a
                href="https://zaplinkcrm.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visite o site
              </a>
              .
            </li>
            <li>
              <strong ><a
                href="https://linkcrm.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkCRM PRO: 
              </a></strong> Integre múltiplos canais de
              comunicação, automatize tarefas e tenha uma visão unificada dos
              seus clientes com o ERP CRM mais avançado do mercado.{" "}
              <a
                href="https://linkcrm.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visite o site
              </a>
              .
            </li>
          </ul>

          <h3>Vantagens Exclusivas para Clientes CashLink</h3>
          <ul className="cs-list cs-style1">
            <li>Website com Hospedagem para ajudar com sua prospecção de clientes apartir de R$ 39,90/mês.</li>
            <li>Descontos de até 70% em planos de assinatura de Parceiros.</li>
            <li>
              Acesso às soluções mais inovadoras para lojas virtuais, ERP's e CRM's.
            </li>
            <li>Atendimento dedicado e suporte exclusivo.</li>
          </ul>

          <h3>Como Solicitar o Seu Desconto?</h3>
          <p>
            Crie sua conta no serviço desejado e entre em contato com a equipe
            da Player4 Studios pelo WhatsApp para solicitar seu benefício,
            informando o CPF ou CNPJ cadastrado:
          </p>
          <div className="cs-height_40"></div>
          <div className="cs-height_75 cs-height_lg_70"></div>
          <div className="text-center cs-height_40">
            <a
              href="https://wa.me/5561991080476?text=Ol%C3%A1%2C%20sou%20cliente%20CashLink%20e%20gostaria%20solicitar%20o%20meu%20desconto%20especial."
              target="_blank"
              rel="noopener noreferrer"
              className="cs-btn cs-primary_btn"
            >
              <span>Solicitar Benefícios</span>
            </a>
            <div className="cs-height_75 cs-height_lg_70"></div>
          </div>
          <div className="cs-height_40"></div>
        </div>
      </div>
    </div>
  );
}
