import React from "react";
import Preloader from "../../component/Preloader/Preloader";
import Simulador from "../../component/Simulador/Simulador";
import Planos from "./Planos/Planos";
import Taxas from "./Taxas/Taxas";
import BannerHeader from "../../component/Layout/BannerHeader";

export default function Home() {
  return (
    <div>
      <Preloader></Preloader>
      <div className="cs-height_60 cs-height_lg_60" ></div>
      <div className="cs-height_60 cs-height_lg_60" ></div>
      <div className="cs-height_60 cs-height_lg_60" ></div>
      <h3 className="cs-section_title text-center mb-8 font-bold text-xl">
        Planos
      </h3>
      <Planos />
      <Taxas />
      <BannerHeader />
      <Simulador/>
      {/* <!-- Start Retail Stores --> */}
      
      {/* <!-- End Retail Stores --> */}
      
    </div>
  );
}
