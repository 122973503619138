import React from "react";
import Preloader from "../../component/Preloader/Preloader";
import Faq from "../../component/Faq/Faq";
import Sobre from "./Sobre/Sobre";

export default function Home() {
  return (
    <div>
      <Preloader></Preloader>
      <div className="cs-height_60 cs-height_lg_60" ></div>
      <div className="cs-height_60 cs-height_lg_60" ></div>
      <div className="cs-height_60 cs-height_lg_60" ></div>
      <h3 className="cs-section_title text-center mb-8 font-bold text-xl">
        A Parceira certa para o seu negócio!
      </h3>
      <Sobre/>
      <Faq/>
      
    </div>
  );
}
