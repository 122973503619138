import React from "react";
import Preloader from "../../component/Preloader/Preloader";
import Simulador from "./Simulador/Simulador"
import Maquininhas from "./Maquininhas/Maquininhas"
import BannerHeader from "../../component/Layout/BannerHeader"

export default function Home() {
  return (
    <div>
      <Preloader></Preloader>
      <div className="cs-height_60 cs-height_lg_60" ></div>
      <div className="cs-height_60 cs-height_lg_60" ></div>
      <div className="cs-height_60 cs-height_lg_60" ></div>
      <h3 className="cs-section_title text-center mb-8 font-bold text-xl">
        Simulador de Taxas
      </h3>
      <Simulador/>
      <BannerHeader/>
      <Maquininhas/>
      <div className="cs-height_75 cs-height_lg_70"></div>
    </div>
  );
}
